<template>
  <div class="my--alert" :class="variant" v-show="show">
    <div class="d-flex">
      <div class="w-100">
        <slot />
      </div>
      <div style="padding: 8px 0 12px 20px" v-if="dismissible">
        <v-icon @click="onDismiss">mdi-close</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['variant', 'show', 'dismissible'],
  components: {},
  data: () => ({}),
  computed: {},
  methods: {
    onDismiss() {
      this.$emit('dismissed');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.my--alert {
  background-color: $color-white;
  padding: 12px 20px;
  border-radius: 16px;
  border: 1px solid $color-gray-1;
  color: $color-text-black;
  font-family: Poppins;
  text-align: center;
  margin-bottom: 16px;
}
.danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  .v-icon {
    color: #721c24;
  }
}
.warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  .v-icon {
    color: #856404;
  }
}
.success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  .v-icon {
    color: #155724;
  }
}
</style>
